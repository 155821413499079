import _ from 'underscore';

import { KioskAvailability } from '@biteinc/enums';
import { I9nHelper } from '@biteinc/helpers';

import { i9nBadgesHtml } from '../../helpers/html_builder';
import { template } from '../../template';

// BITE-7827 & BITE-10020
// Expo printers that are blacklisted should not be displayed in the kiosk list
const blacklistedExpoPrinters = ['X6WY393186'];

app.KioskListView = app.BaseListView.extend({
  turnOffDropdownTemplate: template($('#turnoff-dropdown-template').html()),

  initialize() {
    app.BaseListView.prototype.initialize.apply(this, arguments);

    if (this.collection.getMainCollection) {
      this._kioskList = this.collection.getMainCollection();
    } else {
      this._kioskList = this.collection;
    }
  },

  destroy() {
    app.BaseListView.prototype.apply.destroy(this, arguments);
    this.stopListening(this._kioskList);
  },

  hasFetchedAllData() {
    let hasFetched = app.BaseListView.prototype.hasFetchedAllData.apply(this);
    if (!app.org) {
      hasFetched = hasFetched && app.orgList.hasBeenFetched();
    } else if (!app.location) {
      hasFetched = hasFetched && app.locationList.hasBeenFetched();
    }
    return hasFetched;
  },

  _clickHandlerForAvailability($dropdown, availability, verb, processName) {
    const turningOn = availability === KioskAvailability.ON;
    const confirmation = `Are you sure you want to turn ${turningOn ? 'on' : 'off'} all kiosks${
      turningOn ? '' : ` ${verb.toLowerCase()}`
    }?`;
    if (window.confirm(confirmation)) {
      app.showSavedToast(processName);
      this._kioskList.changeAvailability(availability, {
        error() {
          app.showSavedToast('Failed. :(');
        },
        success() {
          app.showSavedToastAndReload('Success! Reloading in 10sec.', true, 10000);
        },
      });
      $dropdown.toggleClass('open');
    }
    return false;
  },

  __navigateToModel(kiosk) {
    this.scrollToItemWithId(kiosk.id, true);
    this.setSelectedModelId(kiosk.id);
    if (this._cellById[kiosk.id]) {
      this._cellById[kiosk.id].setSelected(true);
    }
  },

  getListSections() {
    let sections = [];
    if (app.location) {
      // Only happens at a location
      sections.push({ models: this._kioskList.models });
      const nonMdmStatuses = app.kioskStatusList.filter((status) => {
        return !status.has('kioskId');
      });
      if (nonMdmStatuses.length) {
        sections.push({
          header: `Untracked Devices ${app.HtmlHelper.biteRightIcon}`,
          models: _.sortBy(nonMdmStatuses, (status) => {
            return status.get('peerId');
          }),
        });
      }
      if (app.expoPrinterList.models.length) {
        const filteredExpoPrinterList = app.expoPrinterList.filter(
          (model) => !blacklistedExpoPrinters.includes(model.attributes.printerName),
        );
        sections.push({
          header: 'Expo Printers',
          models: filteredExpoPrinterList,
        });
      }
    } else {
      // Stati page
      const kiosksByLocationId = _.groupBy(this._kioskList.models, (kiosk) => {
        return kiosk.get('locationId');
      });
      const sortedGroups = _.sortBy(_.values(kiosksByLocationId), (kioskGroup) => {
        const location = app.orgList
          ? app.orgList.getLocationById(kioskGroup[0].get('locationId'))
          : app.locationList.get(kioskGroup[0].get('locationId'));
        return location.debugName().toLowerCase();
      });
      sections = _.map(sortedGroups, (kioskGroup) => {
        const location = app.orgList
          ? app.orgList.getLocationById(kioskGroup[0].get('locationId'))
          : app.locationList.get(kioskGroup[0].get('locationId'));
        const canaryDesignation = location.isCanary() ? '✪ ' : '';
        return {
          header: `${canaryDesignation}${location.debugName()}${i9nBadgesHtml(location.getAllI9nSchemas())}`,
          headerUrl: location.bureauUrl(),
          models: kioskGroup,
        };
      });
    }

    return sections;
  },

  /**
   * @param {KioskAvailability} availability
   * @param {string} toastMessage
   * @param {string} alertPrompt
   * @param {JQuery<HTMLElement>} $dropdown
   */
  _handleChangeAvailabilityText(availability, toastMessage, alertPrompt, $dropdown) {
    const kiosk = _.first(this._kioskList.models);
    const existingClosedMessage = kiosk
      ? kiosk.get('availabilityText')[availability]
      : 'We are currently closed.\nPlease come back later!';

    const $alertContent = $(
      '<textarea style="margin: 20px 0px 5px; height: 110px; width: 100%" />',
    );
    $alertContent.val(existingClosedMessage);
    const confirmCallback = () => {
      const newClosedMessage = $alertContent.val().trim();
      if (newClosedMessage && newClosedMessage !== existingClosedMessage) {
        app.showSavedToast(toastMessage);
        this._kioskList.changeAvailabilityText(newClosedMessage, availability, {
          success() {
            app.showSavedToast('Success!');
          },
        });
      }
    };
    const alertView = new app.AlertView();
    alertView.show(alertPrompt, $alertContent, confirmCallback, () => {});
    $dropdown.toggleClass('open');
    return false;
  },

  _renderToolbar() {
    const self = this;

    const $toolbar = $(
      // prettier-ignore
      '<div class="container kiosk-toolbar">' +
        '<div class="btn-group">' +
          '<div class="btn btn-secondary btn-sm refresh">' +
            '<i class="bi bi-arrow-clockwise"></i>&nbsp;Refresh' +
          '</div>' +
        '</div>' +
        '<div class="change-all-kiosks dropdown">' +
          '<button type="button" class="btn btn-secondary btn-sm">Change All Kiosks...</button>' +
          '<ul class="dropdown-menu collapsed dropdown-menu-right">' +
            '<li class="item turn-on"><a>Turn on</a></li>' +
            '<li class="item turn-off-until-next"><a>Temporarily close until next service</a></li>' +
            '<li class="item turn-off-indefinitely"><a>Turn off indefinitely</a></li>' +
            '<li class="item change-availability-text"><a>Change closed screen copy</a></li>' +
            '<li class="item change-temp-availability-text"><a>Change temporarily closed screen copy</a></li>' +
          '</ul>' +
        '</div>' +
      '</div>',
    );
    this.$listHeader.html($toolbar);

    $toolbar.find('.btn.refresh').click(() => {
      self.collection.fetch({ reset: true });
    });

    const $dropdown = $toolbar.find('.change-all-kiosks');

    if (app.sessionUser.canControlKiosks()) {
      $dropdown.find('button').click(() => {
        $dropdown.toggleClass('open');
      });

      $dropdown.find('.turn-on a').click(() => {
        self._clickHandlerForAvailability(
          $dropdown,
          KioskAvailability.ON,
          '',
          'Turning On All Kiosks',
        );
      });
      $dropdown.find('.turn-off-until-next a').click(() => {
        self._clickHandlerForAvailability(
          $dropdown,
          KioskAvailability.OFF_UNTIL_NEXT,
          'Until Next Service',
          'Turning Off All Kiosks',
        );
      });
      $dropdown.find('.turn-off-indefinitely a').click(() => {
        self._clickHandlerForAvailability(
          $dropdown,
          KioskAvailability.OFF_INDEFINITELY,
          'Indefinitely',
          'Turning Off All Kiosks',
        );
      });
      $dropdown.find('.change-availability-text a').click(() => {
        return this._handleChangeAvailabilityText(
          KioskAvailability.OFF_INDEFINITELY,
          'Changing Closed Screen Copy...',
          'Please enter a new copy for the closed screen:',
          $dropdown,
        );
      });
      $dropdown.find('.change-temp-availability-text a').click(() => {
        return this._handleChangeAvailabilityText(
          KioskAvailability.OFF_UNTIL_NEXT,
          'Changing Temporarily Closed Screen Copy...',
          'Please enter a new copy for the temporarily closed screen:',
          $dropdown,
        );
      });
    } else {
      $dropdown.find('button').prop('disabled', true);
    }
  },

  _renderSearchHint() {
    const liveLocations = app.org ? app.locationList.models : app.orgList.getAllLiveLocations();
    const usedTimezoneKeywords = _.chain(liveLocations)
      .map((location) => {
        return location.getTimezoneSearchToken();
      })
      .uniq()
      .sortBy()
      .value();

    const keywordsByLabel = {
      POS: I9nHelper.getSearchTokensWithTypes(['pos'], ':standalone'),
      Fulfillment: I9nHelper.getSearchTokensWithTypes(['fulfillment']),
      Payments: I9nHelper.getSearchTokensWithTypes(['ecomm-payment', 'kiosk-payment']),
      Loyalty: I9nHelper.getSearchTokensWithTypes(['loyalty']),
      'Stored Value': I9nHelper.getSearchTokensWithTypes(['stored-value', 'comp-card']),
      Timezones: usedTimezoneKeywords,
      OS: [':Android', ':ChromeOS', ':iOS', ':Tizen'],
      MDM: [':EloView', ':Esper', ':WorkspaceOne', ':SignageOS', ':SimpleMDM', ':ChromeOS'],
      Features: [':Canary', ':Delphi'], // TODO add back ':Localization' when we can search using settings
      Peripherals: [
        ':Has-Printer',
        ':No-Printer',
        ':Has-HID',
        ':Has-MSR',
        ':Has-Scanner',
        ':JAWS-Licensed',
        ':JAWS-Unlicensed',
      ],
      Problems: [
        ':Missing',
        ':Missing-Payment',
        ':Missing-Peripherals',
        ':Outdated-Build',
        ':Outdated-OS',
        ':Outdated-WebView',
        ':Pusher-Disconnected',
        ':Unhandled-Model',
        ':Wrong-DPI',
      ],
    };

    const countByKeyword = {};
    if (this.collection.hasBeenFetched()) {
      _.each(keywordsByLabel, (keywords) => {
        _.each(keywords, (keyword) => {
          const count = this.collection.filter((kiosk) => {
            return kiosk.matchesQuery(keyword);
          }).length;
          countByKeyword[keyword] = count;
        });
      });
    }

    app.HtmlHelper.renderSearchHints(
      '',
      keywordsByLabel,
      ':olo :worldpay for all kiosks in olo locations with WP',
      this.$searchHint,
      this.$searchField,
      undefined,
      'search',
      countByKeyword,
    );
  },

  render() {
    app.BaseListView.prototype.render.apply(this, arguments);

    this.$el.addClass('kiosk-list');

    if (!this.collection.hasBeenFetched()) {
      this.collection.fetch({ reset: true });
      this.listenToOnce(this.collection, 'reset', () => {
        if (app.sessionUser.isBite()) {
          this._renderSearchHint();
        }
      });

      // Render again once the locations/orgs are loaded on org-kiosks/bite-kiosks pages resp.
      if (app.org && !app.location && !app.locationList.hasBeenFetched()) {
        this.listenToOnce(app.locationList, 'reset', this._renderList);
      } else if (!app.org && !app.location && !app.orgList.hasBeenFetched()) {
        this.listenToOnce(app.orgList, 'reset', this._renderList);
      }
    }

    if (app.org) {
      this._renderToolbar();
    }

    if (!app.location && app.sessionUser.isBite()) {
      this._renderSearchHint();
    }

    const createButton = this.$('.card-header .right-button-container button.create');
    createButton.html(`${createButton.html()}${app.HtmlHelper.resellerRightIcon}`);

    return this;
  },
});
