import {
  BiteLogAdyenEvent,
  BiteLogCardConnectEvent,
  BiteLogDelphiEvent,
  BiteLogDeviceEvent,
  BiteLogEigenEvent,
  BiteLogElavonEvent,
  BiteLogFatalError,
  BiteLogFreedomPayEvent,
  BiteLogHeartlandEvent,
  BiteLogKioskEvent,
  BiteLogLoyaltyEvent,
  BiteLogMaitredEvent,
  BiteLogOloPayEvent,
  BiteLogPrinterEvent,
  BiteLogPusherEvent,
  BiteLogSquareEvent,
  BiteLogStripeEvent,
  BiteLogType,
  BiteLogVerifonePointCloudEvent,
  BiteLogVerifonePointScaEvent,
  BiteLogWorldpayEvent,
} from '@biteinc/enums';

type Severity = 'warning' | 'error' | 'recovery' | null;

export function getSeverity(type: BiteLogType, status: number): Severity {
  switch (type) {
    case BiteLogType.Simple:
      return null;
    case BiteLogType.FatalError:
      if (status === BiteLogFatalError.None) {
        return 'recovery';
      }
      return 'error';
    case BiteLogType.Printer:
      return severityFromPrinterEvent(status as BiteLogPrinterEvent);
    case BiteLogType.Delphi:
      return severityFromDelphiEvent(status as BiteLogDelphiEvent);
    case BiteLogType.Device:
      return severityFromDeviceEvent(status as BiteLogDeviceEvent);
    case BiteLogType.Maitred:
      return severityFromMaitredEvent(status as BiteLogMaitredEvent);
    case BiteLogType.Pusher:
      return severityFromPusherEvent(status as BiteLogPusherEvent);
    case BiteLogType.Square:
      return severityFromSquareEvent(status as BiteLogSquareEvent);
    case BiteLogType.CardConnect:
      return severityFromCardConnectEvent(status as BiteLogCardConnectEvent);
    case BiteLogType.Worldpay:
      return severityFromWorldpayEvent(status as BiteLogWorldpayEvent);
    case BiteLogType.Kiosk:
      return severityFromKioskEvent(status as BiteLogKioskEvent);
    case BiteLogType.Loyalty:
      return severityFromLoyaltyEvent(status as BiteLogLoyaltyEvent);
    case BiteLogType.Heartland:
      return severityFromHeartlandEvent(status as BiteLogHeartlandEvent);
    case BiteLogType.Stripe:
      return severityFromStripeEvent(status as BiteLogStripeEvent);
    case BiteLogType.FreedomPay:
      return severityFromFreedomPayEvent(status as BiteLogFreedomPayEvent);
    case BiteLogType.Elavon:
      return severityFromElavonEvent(status as BiteLogElavonEvent);
    case BiteLogType.VerifonePointCloud:
      return severityFromVerifonePointCloudEvent(status as BiteLogVerifonePointCloudEvent);
    case BiteLogType.VerifonePointSca:
      return severityFromVerifonePointScaEvent(status as BiteLogVerifonePointScaEvent);
    case BiteLogType.Eigen:
      return severityFromEigenEvent(status as BiteLogEigenEvent);
    case BiteLogType.Adyen:
      return severityFromAdyenEvent(status as BiteLogAdyenEvent);
    case BiteLogType.OloPay:
      return severityFromOloPayEvent(status as BiteLogOloPayEvent);
  }
}

function severityFromPrinterEvent(event: BiteLogPrinterEvent): Severity {
  switch (event) {
    case BiteLogPrinterEvent.OK:
      return null;
    case BiteLogPrinterEvent.AddCommandsError:
    case BiteLogPrinterEvent.ConnectError:
    case BiteLogPrinterEvent.ConnectTimeout:
    case BiteLogPrinterEvent.CoverClosed:
    case BiteLogPrinterEvent.CoverOpen:
    case BiteLogPrinterEvent.Disconnected:
    case BiteLogPrinterEvent.DiscoveryStartError:
    case BiteLogPrinterEvent.PaperEmpty:
    case BiteLogPrinterEvent.PowerOff:
    case BiteLogPrinterEvent.PrintJobFail:
    case BiteLogPrinterEvent.PrintJobTimeout:
    case BiteLogPrinterEvent.QueueCheckFail:
    case BiteLogPrinterEvent.QueueEnterFail:
    case BiteLogPrinterEvent.QueueingDisabled:
    case BiteLogPrinterEvent.ScaleConnectError:
    case BiteLogPrinterEvent.ScaleDisconnected:
    case BiteLogPrinterEvent.ScannerConnectError:
    case BiteLogPrinterEvent.ScannerDisconnected:
    case BiteLogPrinterEvent.SendError:
      return 'error';
    case BiteLogPrinterEvent.PaperLow:
      return 'warning';
  }
}

function severityFromDelphiEvent(event: BiteLogDelphiEvent): Severity {
  switch (event) {
    case BiteLogDelphiEvent.EnrollmentStats:
    case BiteLogDelphiEvent.KairosDetect:
    case BiteLogDelphiEvent.KairosEnroll:
    case BiteLogDelphiEvent.KairosRecognize:
    case BiteLogDelphiEvent.OK:
    case BiteLogDelphiEvent.RecognitionStats:
      return null;
    case BiteLogDelphiEvent.DetectError:
    case BiteLogDelphiEvent.EnrollError:
    case BiteLogDelphiEvent.KairosUnavailable:
    case BiteLogDelphiEvent.Recognize2Face:
    case BiteLogDelphiEvent.RecognizeError:
      return 'error';
  }
}

function severityFromDeviceEvent(event: BiteLogDeviceEvent): Severity {
  switch (event) {
    case BiteLogDeviceEvent.AppUpdate:
    case BiteLogDeviceEvent.JawsLicense:
    case BiteLogDeviceEvent.OK:
    case BiteLogDeviceEvent.Push:
    case BiteLogDeviceEvent.RemoteTestPrintSuccess:
    case BiteLogDeviceEvent.ScannerData:
    case BiteLogDeviceEvent.SessionEnded:
    case BiteLogDeviceEvent.EnteredMenu:
    case BiteLogDeviceEvent.MenuCoverTapped:
    case BiteLogDeviceEvent.SessionStarted:
    case BiteLogDeviceEvent.WhitelistTestSuccess:
      return null;
    case BiteLogDeviceEvent.ApplicationIsBeingTerminated:
    case BiteLogDeviceEvent.BatteryDepleting:
    case BiteLogDeviceEvent.BridgeEvalError:
    case BiteLogDeviceEvent.CameraInitializationError:
    case BiteLogDeviceEvent.CrashlyticsCrash:
    case BiteLogDeviceEvent.EmptyJS:
    case BiteLogDeviceEvent.ErrorSendingOrder:
    case BiteLogDeviceEvent.FailedOrderCreation:
    case BiteLogDeviceEvent.FailedToDownloadMenuAssets:
    case BiteLogDeviceEvent.FailedToStartWebView:
    case BiteLogDeviceEvent.JawsError:
    case BiteLogDeviceEvent.JSCrash:
    case BiteLogDeviceEvent.JSError:
    case BiteLogDeviceEvent.NoNotificationPermissions:
    case BiteLogDeviceEvent.ReachabilityNone:
    case BiteLogDeviceEvent.RemoteTestPrintError:
    case BiteLogDeviceEvent.SelfDestructMaxSessionCountReached:
    case BiteLogDeviceEvent.SelfDestructNoKitchenPrinter:
    case BiteLogDeviceEvent.SelfDestructNoPayment:
    case BiteLogDeviceEvent.SelfDestructNoReceiptPrinter:
    case BiteLogDeviceEvent.StartFail:
    case BiteLogDeviceEvent.WebViewDidCommitNavigation:
    case BiteLogDeviceEvent.WebViewDidTerminate:
    case BiteLogDeviceEvent.WebViewFailedProvisionalNavigation:
    case BiteLogDeviceEvent.WebViewRedirectProvisionalNavigation:
    case BiteLogDeviceEvent.WhitelistTestFailure:
      return 'error';
    case BiteLogDeviceEvent.ReachabilityOK:
      return 'recovery';
    case BiteLogDeviceEvent.ApplicationIsBeingLoggedOut:
    case BiteLogDeviceEvent.ApnReceivedDeprecated:
    case BiteLogDeviceEvent.BatteryStateChange:
    case BiteLogDeviceEvent.BluetoothStateChange:
    case BiteLogDeviceEvent.ConfigChange:
    case BiteLogDeviceEvent.FailedOrderValidation:
    case BiteLogDeviceEvent.FailedToEnterASAM:
    case BiteLogDeviceEvent.FailedToExitASAM:
    case BiteLogDeviceEvent.GazeboExit:
    case BiteLogDeviceEvent.LowBattery:
    case BiteLogDeviceEvent.ManualAppRestart:
    case BiteLogDeviceEvent.MemoryWarning:
    case BiteLogDeviceEvent.RecoveredFromCrash:
    case BiteLogDeviceEvent.ReloadHTML:
      return 'warning';
  }
}

function severityFromKioskEvent(event: BiteLogKioskEvent): Severity {
  switch (event) {
    case BiteLogKioskEvent.ApnSentDeprecated:
    case BiteLogKioskEvent.OK:
    case BiteLogKioskEvent.PushSent:
    case BiteLogKioskEvent.Update:
      return null;
    case BiteLogKioskEvent.ApnSendErrorDeprecated:
    case BiteLogKioskEvent.DeviceRebootError:
    case BiteLogKioskEvent.DisableAdaError:
    case BiteLogKioskEvent.EnableAdaError:
    case BiteLogKioskEvent.MoveMDMGroupError:
    case BiteLogKioskEvent.PushSendError:
    case BiteLogKioskEvent.StatusMissing:
      return 'error';
    case BiteLogKioskEvent.StatusRecovered:
    case BiteLogKioskEvent.TurnedOn:
      return 'recovery';
    case BiteLogKioskEvent.DeviceReboot:
    case BiteLogKioskEvent.DisabledAda:
    case BiteLogKioskEvent.EnabledAda:
    case BiteLogKioskEvent.Inactive:
    case BiteLogKioskEvent.MovedMDMGroup:
    case BiteLogKioskEvent.TurnedOff:
    case BiteLogKioskEvent.TurnedOffIndefinitely:
      return 'warning';
  }
}

function severityFromLoyaltyEvent(event: BiteLogLoyaltyEvent): Severity {
  switch (event) {
    case BiteLogLoyaltyEvent.Authed:
    case BiteLogLoyaltyEvent.CouponApplied:
    case BiteLogLoyaltyEvent.CouponRemoved:
    case BiteLogLoyaltyEvent.CouponSingleRedemptionRedeemed:
    case BiteLogLoyaltyEvent.GiftCardApplied:
    case BiteLogLoyaltyEvent.GiftCardInquiry:
    case BiteLogLoyaltyEvent.GiftCardRefunded:
    case BiteLogLoyaltyEvent.OK:
    case BiteLogLoyaltyEvent.RewardRemoved:
    case BiteLogLoyaltyEvent.RewardsApplied:
    case BiteLogLoyaltyEvent.RewardsFetched:
    case BiteLogLoyaltyEvent.CompCardApplied:
    case BiteLogLoyaltyEvent.CompCardRemoved:
      return null;
    case BiteLogLoyaltyEvent.AuthError:
    case BiteLogLoyaltyEvent.CouponApplyError:
    case BiteLogLoyaltyEvent.CouponRemoveError:
    case BiteLogLoyaltyEvent.GiftCardApplyError:
    case BiteLogLoyaltyEvent.GiftCardInquiryError:
    case BiteLogLoyaltyEvent.GiftCardRefundError:
    case BiteLogLoyaltyEvent.RewardRemoveError:
    case BiteLogLoyaltyEvent.RewardsApplyError:
    case BiteLogLoyaltyEvent.RewardsFetchError:
    case BiteLogLoyaltyEvent.CompCardApplyError:
    case BiteLogLoyaltyEvent.CompCardRemoveError:
      return 'error';
    case BiteLogLoyaltyEvent.AuthSkipped:
    case BiteLogLoyaltyEvent.CouponApplySkipped:
    case BiteLogLoyaltyEvent.CouponRemoveSkipped:
    case BiteLogLoyaltyEvent.GiftCardApplySkipped:
    case BiteLogLoyaltyEvent.GiftCardInquirySkipped:
    case BiteLogLoyaltyEvent.GiftCardRefundSkipped:
    case BiteLogLoyaltyEvent.RewardRemoveSkipped:
    case BiteLogLoyaltyEvent.RewardsApplyBestEffortFailed:
    case BiteLogLoyaltyEvent.RewardsApplySkipped:
    case BiteLogLoyaltyEvent.RewardsFetchSkipped:
      return 'warning';
  }
}

function severityFromMaitredEvent(event: BiteLogMaitredEvent): Severity {
  switch (event) {
    case BiteLogMaitredEvent.DeviceLogsReceived:
    case BiteLogMaitredEvent.FulfillmentPayloadFetchSuccess:
    case BiteLogMaitredEvent.FulfillmentPayloadSendAttemptSuccess:
    case BiteLogMaitredEvent.GuestReceiptEmail:
    case BiteLogMaitredEvent.GuestReceiptSms:
    case BiteLogMaitredEvent.IdempotentOrderCheckin:
    case BiteLogMaitredEvent.KioskPaymentTerminalStatusCheck:
    case BiteLogMaitredEvent.KioskPaymentTerminalStoppedDuringPayment:
    case BiteLogMaitredEvent.LoyaltySignupSms:
    case BiteLogMaitredEvent.MenuFetch:
    case BiteLogMaitredEvent.MenuSyncDuration:
    case BiteLogMaitredEvent.MenuSyncDurationAndWaitTime:
    case BiteLogMaitredEvent.MenuSyncWaitTimeQuicksandQueue:
    case BiteLogMaitredEvent.NotificationAddToQueue:
    case BiteLogMaitredEvent.OK:
    case BiteLogMaitredEvent.OrderAddToSendOrderQueue:
    case BiteLogMaitredEvent.OrderAddToValidateOrderQueue:
    case BiteLogMaitredEvent.OrderCancelled:
    case BiteLogMaitredEvent.OrderCheckin:
    case BiteLogMaitredEvent.OrderClientSendDuration:
    case BiteLogMaitredEvent.OrderClosed:
    case BiteLogMaitredEvent.OrderCouponAdd:
    case BiteLogMaitredEvent.OrderCouponRemove:
    case BiteLogMaitredEvent.OrderCompCardApply:
    case BiteLogMaitredEvent.OrderCompCardRemove:
    case BiteLogMaitredEvent.OrderCreated:
    case BiteLogMaitredEvent.OrderLoyaltyAddUnappliedReward:
    case BiteLogMaitredEvent.OrderLoyaltyAddReward:
    case BiteLogMaitredEvent.OrderLoyaltyAuth:
    case BiteLogMaitredEvent.OrderLoyaltySignOut:
    case BiteLogMaitredEvent.OrderLoyaltyGetAvailableRewards:
    case BiteLogMaitredEvent.OrderLoyaltyGetAvailableRewardsNoOrder:
    case BiteLogMaitredEvent.OrderLoyaltyRemoveReward:
    case BiteLogMaitredEvent.OrderPatchOnQsr:
    case BiteLogMaitredEvent.OrderPosSendAttemptDuration:
    case BiteLogMaitredEvent.OrderReceived:
    case BiteLogMaitredEvent.OrderRefunded:
    case BiteLogMaitredEvent.OrderAbandoned:
    case BiteLogMaitredEvent.OrderSentToCompCard:
    case BiteLogMaitredEvent.OrderSentToFulfillment:
    case BiteLogMaitredEvent.OrderSentToLoyalty:
    case BiteLogMaitredEvent.OrderSentToPos:
    case BiteLogMaitredEvent.OrderUpdated:
    case BiteLogMaitredEvent.OrderValidated:
    case BiteLogMaitredEvent.OrderValidatedOnLoyalty:
    case BiteLogMaitredEvent.OrderValidatedOnOther:
    case BiteLogMaitredEvent.OrderValidateTip:
    case BiteLogMaitredEvent.OrderVoidedOnCompCard:
    case BiteLogMaitredEvent.OrderVoidedOnLoyalty:
    case BiteLogMaitredEvent.OrderWaitTimeSendOrderQueue:
    case BiteLogMaitredEvent.OrderWaitTimeValidateOrderQueue:
    case BiteLogMaitredEvent.OrderWaitTimeCreateOrderQueue:
    case BiteLogMaitredEvent.OrderAddToCreateOrderQueue:
    case BiteLogMaitredEvent.OrderCreatedOnPos:
    case BiteLogMaitredEvent.OrderCreatedOnPosSkipped:
    case BiteLogMaitredEvent.PaymentAddToEcommQueue:
    case BiteLogMaitredEvent.PaymentAddToKioskApiQueue:
    case BiteLogMaitredEvent.PaymentAddToRefundQueue:
    case BiteLogMaitredEvent.PaymentFlashCreate:
    case BiteLogMaitredEvent.PaymentKioskApiCancel:
    case BiteLogMaitredEvent.PaymentKioskApiCreate:
    case BiteLogMaitredEvent.PaymentKioskPreReadCardQueue:
    case BiteLogMaitredEvent.PaymentKioskPreReadCard:
    case BiteLogMaitredEvent.PaymentKioskApiRecovery:
    case BiteLogMaitredEvent.PaymentKioskCreate:
    case BiteLogMaitredEvent.PaymentRefund:
    case BiteLogMaitredEvent.PaymentStoredValueCreate:
    case BiteLogMaitredEvent.PaymentStoredValueInquire:
    case BiteLogMaitredEvent.PaymentStripeDisbursementAddToQueue:
    case BiteLogMaitredEvent.PaymentStripeDisbursementReversalAddToQueue:
    case BiteLogMaitredEvent.PaymentWaitTimeKioskApiPaymentQueue:
    case BiteLogMaitredEvent.QueueJobCompleted:
    case BiteLogMaitredEvent.QueueWaitingCountOk:
    case BiteLogMaitredEvent.ReportSend:
    case BiteLogMaitredEvent.ScreenshotReceived:
    case BiteLogMaitredEvent.SmsNotifierSend:
    case BiteLogMaitredEvent.TransactionCommitted:
    case BiteLogMaitredEvent.TransactionRefundIssued:
    case BiteLogMaitredEvent.Webhook86dItemsSuccessful:
    case BiteLogMaitredEvent.WebhookReceived:
    case BiteLogMaitredEvent.WebhookSend:
      return null;
    case BiteLogMaitredEvent.ExpoPrintAuthError:
    case BiteLogMaitredEvent.FulfillmentPayloadFetchError:
    case BiteLogMaitredEvent.FulfillmentPayloadSendAttemptError:
    case BiteLogMaitredEvent.GuestReceiptEmailError:
    case BiteLogMaitredEvent.GuestReceiptSmsError:
    case BiteLogMaitredEvent.KioskPaymentTerminalStatusCheckError:
    case BiteLogMaitredEvent.KioskPaymentTerminalStoppedDuringPaymentError:
    case BiteLogMaitredEvent.LoyaltySignupSmsError:
    case BiteLogMaitredEvent.MenuFetchError:
    case BiteLogMaitredEvent.NotificationAddToQueueError:
    case BiteLogMaitredEvent.NotifierSendError:
    case BiteLogMaitredEvent.OrderAddToSendOrderQueueError:
    case BiteLogMaitredEvent.OrderAddToValidateOrderQueueError:
    case BiteLogMaitredEvent.OrderCancelledError:
    case BiteLogMaitredEvent.OrderCheckinError:
    case BiteLogMaitredEvent.OrderClosedError:
    case BiteLogMaitredEvent.OrderCouponAddError:
    case BiteLogMaitredEvent.OrderCouponRemoveError:
    case BiteLogMaitredEvent.OrderCompCardApplyError:
    case BiteLogMaitredEvent.OrderCompCardRemoveError:
    case BiteLogMaitredEvent.OrderCourierValidateError:
    case BiteLogMaitredEvent.OrderCreatedError:
    case BiteLogMaitredEvent.OrderGetSendOrderQueueError:
    case BiteLogMaitredEvent.OrderGetValidateOrderQueueError:
    case BiteLogMaitredEvent.OrderLoyaltyAddUnappliedRewardError:
    case BiteLogMaitredEvent.OrderLoyaltyAddRewardError:
    case BiteLogMaitredEvent.OrderLoyaltyAuthError:
    case BiteLogMaitredEvent.OrderLoyaltySignOutError:
    case BiteLogMaitredEvent.OrderLoyaltyGetAvailableRewardsError:
    case BiteLogMaitredEvent.OrderLoyaltyGetAvailableRewardsNoOrderError:
    case BiteLogMaitredEvent.OrderLoyaltyRemoveRewardError:
    case BiteLogMaitredEvent.OrderPatchOnQsrError:
    case BiteLogMaitredEvent.OrderRefundError:
    case BiteLogMaitredEvent.OrderAbandonError:
    case BiteLogMaitredEvent.OrderSendToCompCardError:
    case BiteLogMaitredEvent.OrderSendToFulfillmentError:
    case BiteLogMaitredEvent.OrderSendToLoyaltyError:
    case BiteLogMaitredEvent.OrderSendToPosError:
    case BiteLogMaitredEvent.OrderUpdatedError:
    case BiteLogMaitredEvent.OrderValidateError:
    case BiteLogMaitredEvent.OrderValidateTipError:
    case BiteLogMaitredEvent.OrderValidationOnLoyaltyError:
    case BiteLogMaitredEvent.OrderValidationOnOtherError:
    case BiteLogMaitredEvent.OrderVoidOnCompCardError:
    case BiteLogMaitredEvent.OrderVoidOnLoyaltyError:
    case BiteLogMaitredEvent.PaymentAddToEcommQueueError:
    case BiteLogMaitredEvent.PaymentAddToKioskApiQueueError:
    case BiteLogMaitredEvent.PaymentFlashCreateError:
    case BiteLogMaitredEvent.PaymentGetEcommQueueError:
    case BiteLogMaitredEvent.PaymentGetKioskApiQueueError:
    case BiteLogMaitredEvent.PaymentGetRefundQueueError:
    case BiteLogMaitredEvent.PaymentGetStoredValueCreateQueueError:
    case BiteLogMaitredEvent.PaymentKioskApiCancelError:
    case BiteLogMaitredEvent.PaymentKioskApiCreateError:
    case BiteLogMaitredEvent.PaymentKioskPreReadCardQueueError:
    case BiteLogMaitredEvent.PaymentKioskPreReadCardError:
    case BiteLogMaitredEvent.PaymentKioskApiRecoveryError:
    case BiteLogMaitredEvent.PaymentKioskCreateError:
    case BiteLogMaitredEvent.PaymentRefundAddToRefundQueueError:
    case BiteLogMaitredEvent.PaymentRefundError:
    case BiteLogMaitredEvent.PaymentStoredValueCreateError:
    case BiteLogMaitredEvent.PaymentStoredValueInquireError:
    case BiteLogMaitredEvent.PaymentStripeDisbursementAddToQueueError:
    case BiteLogMaitredEvent.PaymentStripeDisbursementError:
    case BiteLogMaitredEvent.PaymentStripeDisbursementReversalAddToQueueError:
    case BiteLogMaitredEvent.PaymentStripeDisbursementReversalError:
    case BiteLogMaitredEvent.QueueJobFailed:
    case BiteLogMaitredEvent.QueueWaitingCountError:
    case BiteLogMaitredEvent.ReportSendError:
    case BiteLogMaitredEvent.SmsNotifierSendError:
    case BiteLogMaitredEvent.TransactionCommitError:
    case BiteLogMaitredEvent.TransactionRefundError:
    case BiteLogMaitredEvent.Webhook86dItemsFailed:
    case BiteLogMaitredEvent.WebhookSendError:
    case BiteLogMaitredEvent.PaymentNativeResponseException:
    case BiteLogMaitredEvent.OrderAddToCreateOrderQueueError:
    case BiteLogMaitredEvent.OrderCreatedOnPosError:
    case BiteLogMaitredEvent.OrderGetCreateOrderQueueError:
      return 'error';
    case BiteLogMaitredEvent.IdempotentOrderCancelled:
    case BiteLogMaitredEvent.IdempotentOrderClosed:
    case BiteLogMaitredEvent.IdempotentOrderCreated:
    case BiteLogMaitredEvent.IdempotentOrderReceived:
    case BiteLogMaitredEvent.IdempotentOrderRefunded:
    case BiteLogMaitredEvent.LateOrderReceived:
    case BiteLogMaitredEvent.OrderLoyaltyIdempotentRemoveReward:
    case BiteLogMaitredEvent.OrderSendingSkipped:
    case BiteLogMaitredEvent.OrderSendToCompCardSkipped:
    case BiteLogMaitredEvent.OrderSendToFulfillmentSkipped:
    case BiteLogMaitredEvent.OrderSendToLoyaltySkipped:
    case BiteLogMaitredEvent.OrderValidationOnLoyaltySkipped:
    case BiteLogMaitredEvent.OrderValidationOnOtherSkipped:
    case BiteLogMaitredEvent.OrderValidationSkipped:
    case BiteLogMaitredEvent.OrderVoidOnCompCardSkipped:
    case BiteLogMaitredEvent.OrderVoidOnLoyaltySkipped:
    case BiteLogMaitredEvent.PaymentFlashCreateIdempotent:
    case BiteLogMaitredEvent.PaymentKioskApiCreateIdempotent:
    case BiteLogMaitredEvent.PaymentKioskCreateIdempotent:
    case BiteLogMaitredEvent.PaymentRefundIdempotent:
    case BiteLogMaitredEvent.PaymentStoredValueCreateIdempotent:
    case BiteLogMaitredEvent.QueueJobStalled:
    case BiteLogMaitredEvent.SmsNotifierSendErrorLight:
    case BiteLogMaitredEvent.PaymentNativeResponseCorrection:
      return 'warning';
  }
}

function severityFromPusherEvent(event: BiteLogPusherEvent): Severity {
  switch (event) {
    case BiteLogPusherEvent.Connected:
    case BiteLogPusherEvent.Ok:
    case BiteLogPusherEvent.Subscribed:
      return null;
    case BiteLogPusherEvent.Disconnected:
    case BiteLogPusherEvent.Error:
    case BiteLogPusherEvent.FailedToConnect:
    case BiteLogPusherEvent.FailedToSubscribe:
      return 'error';
  }
}

function severityFromSquareEvent(event: BiteLogSquareEvent): Severity {
  switch (event) {
    case BiteLogSquareEvent.AuthorizationSuccess:
    case BiteLogSquareEvent.None:
    case BiteLogSquareEvent.PairingSuccess:
    case BiteLogSquareEvent.ReaderSettingsSuccess:
      return null;
    case BiteLogSquareEvent.AuthorizationError:
    case BiteLogSquareEvent.CheckoutErrNotAuthorized:
    case BiteLogSquareEvent.CheckoutErrUsageError:
    case BiteLogSquareEvent.CheckoutErrorDeprecated:
    case BiteLogSquareEvent.PairingError:
    case BiteLogSquareEvent.ReaderSettingsError:
      return 'error';
    case BiteLogSquareEvent.CheckoutCancelled:
    case BiteLogSquareEvent.CheckoutLocationServicesDisabled:
    case BiteLogSquareEvent.CheckoutNoNetwork:
    case BiteLogSquareEvent.CheckoutTimeout:
    case BiteLogSquareEvent.ReaderDisconnected:
      return 'warning';
  }
}

function severityFromCardConnectEvent(event: BiteLogCardConnectEvent): Severity {
  switch (event) {
    case BiteLogCardConnectEvent.AuthAndCapture:
    case BiteLogCardConnectEvent.AuthTransaction:
    case BiteLogCardConnectEvent.None:
      return null;
    case BiteLogCardConnectEvent.AuthAndCaptureError:
    case BiteLogCardConnectEvent.AuthTransactionError:
    case BiteLogCardConnectEvent.PaymentError:
    case BiteLogCardConnectEvent.RefundError:
      return 'error';
    case BiteLogCardConnectEvent.TerminalDisconnected:
    case BiteLogCardConnectEvent.UpdateIdleTextError:
      return 'warning';
  }
}

function severityFromWorldpayEvent(event: BiteLogWorldpayEvent): Severity {
  switch (event) {
    case BiteLogWorldpayEvent.AuthTransaction:
    case BiteLogWorldpayEvent.None:
    case BiteLogWorldpayEvent.SaleTransaction:
      return null;
    case BiteLogWorldpayEvent.AuthTransactionError:
    case BiteLogWorldpayEvent.PaymentError:
    case BiteLogWorldpayEvent.RefundError:
    case BiteLogWorldpayEvent.SaleTransactionError:
      return 'error';
    case BiteLogWorldpayEvent.TerminalDisconnected:
    case BiteLogWorldpayEvent.UpdateIdleTextError:
      return 'warning';
  }
}

function severityFromHeartlandEvent(event: BiteLogHeartlandEvent): Severity {
  switch (event) {
    case BiteLogHeartlandEvent.AuthTransaction:
    case BiteLogHeartlandEvent.BalanceInquiry:
    case BiteLogHeartlandEvent.None:
    case BiteLogHeartlandEvent.SaleTransaction:
    case BiteLogHeartlandEvent.TerminalConnected:
    case BiteLogHeartlandEvent.SetupDevice:
      return null;
    case BiteLogHeartlandEvent.SaleTransactionError:
    case BiteLogHeartlandEvent.BalanceInquiryError:
    case BiteLogHeartlandEvent.RefundError:
    case BiteLogHeartlandEvent.AuthTransactionError:
    case BiteLogHeartlandEvent.TerminalInitFailed:
      return 'error';
    case BiteLogHeartlandEvent.TerminalDisconnected:
    case BiteLogHeartlandEvent.UpdateIdleTextError:
    case BiteLogHeartlandEvent.DeviceResetFailed:
      return 'warning';
  }
}

function severityFromStripeEvent(event: BiteLogStripeEvent): Severity {
  switch (event) {
    case BiteLogStripeEvent.None:
    case BiteLogStripeEvent.PaymentIntent:
    case BiteLogStripeEvent.Refund:
      return null;
    case BiteLogStripeEvent.PaymentIntentError:
    case BiteLogStripeEvent.RefundError:
    case BiteLogStripeEvent.TokenizationError:
      return 'error';
    case BiteLogStripeEvent.TerminalDisconnected:
      return 'warning';
  }
}

function severityFromElavonEvent(event: BiteLogElavonEvent): Severity {
  switch (event) {
    case BiteLogElavonEvent.None:
    case BiteLogElavonEvent.SaleTransaction:
      return null;
    case BiteLogElavonEvent.SaleTransactionError:
      return 'error';
    case BiteLogElavonEvent.TerminalDisconnected:
    case BiteLogElavonEvent.UpdateIdleTextError:
      return 'warning';
  }
}

function severityFromVerifonePointCloudEvent(event: BiteLogVerifonePointCloudEvent): Severity {
  switch (event) {
    case BiteLogVerifonePointCloudEvent.None:
    case BiteLogVerifonePointCloudEvent.Payment:
    case BiteLogVerifonePointCloudEvent.Refund:
      return null;
    case BiteLogVerifonePointCloudEvent.PaymentError:
    case BiteLogVerifonePointCloudEvent.RefundError:
      return 'error';
    case BiteLogVerifonePointCloudEvent.TerminalDisconnected:
      return 'warning';
  }
}

function severityFromVerifonePointScaEvent(event: BiteLogVerifonePointScaEvent): Severity {
  switch (event) {
    case BiteLogVerifonePointScaEvent.None:
    case BiteLogVerifonePointScaEvent.SaleTransaction:
    case BiteLogVerifonePointScaEvent.Success:
      return null;
    case BiteLogVerifonePointScaEvent.AuthenticationError:
    case BiteLogVerifonePointScaEvent.KeyStoreError:
    case BiteLogVerifonePointScaEvent.PortError:
    case BiteLogVerifonePointScaEvent.SaleTransactionError:
    case BiteLogVerifonePointScaEvent.SyncTimeError:
      return 'error';
    case BiteLogVerifonePointScaEvent.TerminalDisconnected:
    case BiteLogVerifonePointScaEvent.TerminalRestarted:
      return 'warning';
  }
}

function severityFromEigenEvent(event: BiteLogEigenEvent): Severity {
  switch (event) {
    case BiteLogEigenEvent.None:
    case BiteLogEigenEvent.Payment:
    case BiteLogEigenEvent.Refund:
      return null;
    case BiteLogEigenEvent.PaymentError:
    case BiteLogEigenEvent.RefundError:
      return 'error';
    case BiteLogEigenEvent.TerminalDisconnected:
      return 'warning';
  }
}

function severityFromAdyenEvent(event: BiteLogAdyenEvent): Severity {
  switch (event) {
    case BiteLogAdyenEvent.None:
    case BiteLogAdyenEvent.Payment:
    case BiteLogAdyenEvent.Refund:
      return null;
    case BiteLogAdyenEvent.PaymentError:
    case BiteLogAdyenEvent.RefundError:
      return 'error';
    case BiteLogAdyenEvent.TerminalDisconnected:
      return 'warning';
  }
}

function severityFromOloPayEvent(event: BiteLogOloPayEvent): Severity {
  switch (event) {
    case BiteLogOloPayEvent.None:
    case BiteLogOloPayEvent.Payment:
    case BiteLogOloPayEvent.Refund:
      return null;
    case BiteLogOloPayEvent.PaymentError:
    case BiteLogOloPayEvent.RefundError:
      return 'error';
  }
}

function severityFromFreedomPayEvent(event: BiteLogFreedomPayEvent): Severity {
  switch (event) {
    case BiteLogFreedomPayEvent.AuthTransaction:
    case BiteLogFreedomPayEvent.InstallPal:
    case BiteLogFreedomPayEvent.Logs:
    case BiteLogFreedomPayEvent.None:
    case BiteLogFreedomPayEvent.Payment:
    case BiteLogFreedomPayEvent.Refund:
    case BiteLogFreedomPayEvent.ReverseRefund:
    case BiteLogFreedomPayEvent.SaleTransaction:
      return null;
    case BiteLogFreedomPayEvent.AuthTransactionError:
    case BiteLogFreedomPayEvent.IframeInitError:
    case BiteLogFreedomPayEvent.InstallPalError:
    case BiteLogFreedomPayEvent.PaymentError:
    case BiteLogFreedomPayEvent.RefundError:
    case BiteLogFreedomPayEvent.SaleTransactionError:
    case BiteLogFreedomPayEvent.TokenizationError:
      return 'error';
    case BiteLogFreedomPayEvent.TerminalDisconnected:
    case BiteLogFreedomPayEvent.UpdateIdleTextError:
      return 'warning';
  }
}
