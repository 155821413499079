import _ from 'underscore';

import { MenuRules } from '@biteinc/business-rules';
import { ModelType, ModGroupDisplayStyle } from '@biteinc/enums';
import { MathHelper } from '@biteinc/helpers';
import { modGroupSchema } from '@biteinc/schemas';

app.ModGroup = app.MenuItemArray.extend({
  ModelName: 'modGroup',
  Schema: modGroupSchema,
  Type: ModelType.ModGroup,

  defaults() {
    const defaults = {
      items: [],
    };
    if (app.vendorList && app.vendorList.hasBeenFetched() && app.vendorList.size()) {
      defaults.vendorId = (
        app.vendorList.isEveryVendorIntegrated()
          ? app.vendorList.getDefaultVendorOrFallback()
          : app.vendorList.getDefaultNonintegratedVendor()
      ).id;
    }
    return defaults;
  },

  // Recursively check if the mod is present somewhere in the tree
  hasVisibleModWithId(modId) {
    return this.get('items').some((itemRef) => {
      if (itemRef._id === modId) {
        return true;
      }
      const mod = app.modList.get(itemRef._id);
      return !!mod?.hasModWithId(modId);
    });
  },

  getVisibleInPlaces() {
    const models = [];
    app.menuItemList.each((item) => {
      if (!item.isCompoundItem() && item.hasModGroupWithId(this.id)) {
        models.push(item);
      }
    });
    app.modList.each((mod) => {
      if (mod.hasModGroupWithId(this.id)) {
        models.push(mod);
      }
    });
    return models;
  },

  fieldNamesThatCanBeCopiedToOtherModels() {
    return MenuRules.modGroupFieldNamesThatCanBeCopiedToOtherModels();
  },

  getCopyableFieldTooltip(fieldName) {
    switch (fieldName) {
      case 'items':
        return {
          text: 'Copy properties of mods. Available mods are unaffected.',
        };
      default:
        return null;
    }
  },

  _getItemRefProperty(modId, propertyName, defaultValue) {
    const i9nItemRefs = this.get('i9nItems') || [];
    for (let i = 0; i < this.get('items').length; i++) {
      const itemRef = this.get('items')[i];
      if (itemRef._id === modId) {
        if (_.has(itemRef, propertyName)) {
          return itemRef[propertyName];
        }
        if (i9nItemRefs.length > i && _.has(i9nItemRefs[i], propertyName)) {
          return i9nItemRefs[i][propertyName];
        }
      }
    }
    return defaultValue;
  },

  modIsSelectedByDefault(modId) {
    return (
      this._getItemRefProperty(modId, 'selectedByDefault', false) ||
      this._getItemRefProperty(modId, 'selectedByDefaultQuantity', 0) > 0
    );
  },

  modMustBeAlwaysSent(modId) {
    return !!this._getItemRefProperty(modId, 'mustBeAlwaysSent', false);
  },

  getIntraLocationCopyPayload(fieldNames, toModelIds) {
    return {
      fromModGroupId: this.id,
      toModGroupIds: toModelIds,
      fieldNames,
    };
  },

  shouldSpecificFieldBeCopiedByDefault(fieldName) {
    if (fieldName === 'items') {
      return this.get('items').some((itemRef) => {
        // Check if any itemRef has any fields other than the basic _id
        return Object.keys(itemRef).length > 1;
      });
    }
    return app.MenuItemArray.prototype.shouldSpecificFieldBeCopiedByDefault.apply(this, [
      fieldName,
    ]);
  },

  hasModWithNestedModGroups() {
    return this.get('items').some((item) => {
      const mod = app.modList.get(item._id);
      if (!mod) {
        // Mod list may not have been fetched yet
        return false;
      }

      const modPriceOptions = mod.get('priceOptions');
      // multiple price options cannot be handled
      if (modPriceOptions.length > 1) {
        return true;
      }

      return modPriceOptions[0].addonSetIds && modPriceOptions[0].addonSetIds > 0;
    });
  },

  fieldIsPermanent(field, subProperty) {
    const fieldName = subProperty ? [subProperty, field].join('.') : field;
    if (app.AbstractModel.prototype.fieldIsPermanent.apply(this, arguments)) {
      return true;
    }

    if (fieldName === 'displayStyle' && this.hasModWithNestedModGroups()) {
      return true;
    }

    if (this.getFullVendor().syncsModelsWithType(this.Type)) {
      if (['minSelectable', 'maxSelectable', 'vendorId'].includes(fieldName)) {
        return true;
      }
      // POS Schema must exist since the location syncs mod groups
      // manuallySetMultipleOfSameMod means the integration does not sync multi-mod values,
      // instead allowing the user to set them all manually.
      if (
        !app.location.getPosI9nSchema().manuallySetMultipleOfSameMod &&
        [
          'allowMultipleOfSameAddon',
          'minAggregateQuantity',
          'maxAggregateQuantity',
          'minChoiceQuantity',
          'maxChoiceQuantity',
          'choiceQuantityIncrement',
        ].includes(fieldName)
      ) {
        return true;
      }
    }

    return false;
  },

  fieldIsHidden(field, subProperty) {
    const fieldName = subProperty ? [subProperty, field].join('.') : field;
    if (app.AbstractModel.prototype.fieldIsHidden.apply(this, arguments)) {
      return true;
    }

    if (fieldName === 'vendorId' && app.vendorList.isEveryVendorIntegrated()) {
      return true;
    }

    return false;
  },

  getFieldCollection(field, subProperty, _includeAllValues, _keyModel) {
    const fieldName = subProperty ? [subProperty, field].join('.') : field;
    switch (fieldName) {
      case 'vendorId':
        return this.getFullVendor().syncsModelsWithType(this.Type)
          ? app.vendorList.buildSingletonVendorList(this.get('vendorId'))
          : app.vendorList.buildNonintegratedVendorsList();
      case 'virtualSubGroups.items': {
        const mods = [];
        _.each(this.get('items'), (itemRef) => {
          const mod = app.modList.get(itemRef._id);
          if (mod) {
            mods.push(mod);
          }
        });
        const ModList = app.AbstractCollection.extend({
          model: app.Mod,
        });
        return new ModList(mods);
      }
      case 'posWalletSettings.values': {
        const models = (this.get('posWalletSettings')?.values || []).map((value) => {
          return new app.AbstractModel({
            // This _id gets created in mod_group_list.js:parse
            _id: value._id,
            name: `Quantity cap: ${
              value.inclusiveQuantityCap
            }; Price per modifier $${MathHelper.displayPrice(value.price)}`,
          });
        });
        return new app.AbstractCollection(models);
      }
      case 'displayStyle':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: ModGroupDisplayStyle,
          nameGenerator: (displayStyle) => {
            switch (displayStyle) {
              case ModGroupDisplayStyle.Default:
                return 'Default';
              case ModGroupDisplayStyle.SizeGroup:
                return 'Size Group';
              default:
                throw new Error(`unrecognized modGroup displayStyle value: ${displayStyle}`);
            }
          },
        });
      case 'activeTimings':
        return app.menuTimingList;
      default:
        return null;
    }
  },

  subtitleForListFieldElement(field, element) {
    switch (field) {
      case 'items':
        return this.getItemDescription(element.id) || '';
    }
    return '';
  },

  getListFieldElementAttributesFromModel(field, element) {
    return { _id: element.id };
  },
});
