import $ from 'jquery';
import Sortable from 'sortablejs';
import _ from 'underscore';

import { template } from './template';

app.Dropdown = function Dropdown() {};
_.extend(app.Dropdown.prototype, {
  destroy() {
    if (this.sortable) {
      this.sortable.destroy();
      this.sortable = null;
    }
  },

  setupDropdown($panel, collection, options) {
    const $dropdown = $panel.find('.item-dropdown');
    const $list = $dropdown.find('.dropdown-menu');

    const filterList = (string) => {
      const query = (string || '').trim().toLowerCase();
      $list.find('li.item').remove();

      collection.each((object) => {
        if (options.validate && !options.validate(object)) {
          return;
        }

        let displayName = '';
        if (options.getDisplayName) {
          displayName = options.getDisplayName(object);
        } else if (object.displayNameHtml) {
          displayName = object.displayNameHtml();
        } else {
          displayName = object.displayName();
        }

        let subtitleSpan = '';
        const subtitle = options.getSubtitle && options.getSubtitle(object);
        if (subtitle) {
          subtitleSpan = `<span class="subtitle">${subtitle.split('\n').join('<br/>')}</span>`;
        }

        if (query) {
          let matches = true;
          if (object.matchesQuery) {
            matches = object.matchesQuery(query, displayName, subtitle);
          } else {
            matches =
              displayName.toLowerCase().indexOf(query) >= 0 ||
              subtitle?.toLowerCase().indexOf(query) >= 0;
          }
          if (!matches) {
            return;
          }
        }

        const $el = $(
          `<li class="item"><a class="clearfix">${displayName}${subtitleSpan}</a></li>`,
        );
        $list.append($el);
        $el.find('a').click(() => {
          options.onAdd(object);
          if (options.onSelect) {
            options.onSelect(object);
          }
        });
      });
    };

    if (this._onDropdownShow) {
      // Remove the previous event listener if it exists since it would have an outdated collection
      $dropdown[0].removeEventListener('show.bs.dropdown', this._onDropdownShow);
    }

    this._onDropdownShow = () => {
      $dropdown.toggleClass('open', true);
      $list.find('li').remove();

      if (collection.size() > 5) {
        $list.append('<li class="search"><input type="text"/></li>');
        const $searchField = $list.find('li.search input');
        $searchField.attr(
          'placeholder',
          collection.type() ? `search ${collection.type()}` : 'search',
        );

        // Prevent the dropdown from hiding when user clicks on the search bar
        $searchField.click((e) => {
          e.stopPropagation();
        });

        $searchField.on('keyup', () => {
          filterList($searchField.val());
          return false;
        });
      }

      filterList('');
    };

    $dropdown[0].addEventListener('show.bs.dropdown', this._onDropdownShow);

    $dropdown[0].addEventListener('shown.bs.dropdown', () => {
      const $searchField = $list.find('li.search input');
      $searchField.focus();
    });

    $dropdown[0].addEventListener('hide.bs.dropdown', () => {
      $dropdown.toggleClass('open', false);
    });
  },

  setupRows($itemPanel, objectIds, List, options) {
    $itemPanel.find('button.add').prop('disabled', true);

    const $itemListPanel = $itemPanel.find('.item-list');
    $itemListPanel.find('div').remove();

    const availableObjects = [];
    $.each(objectIds || [], (i, objectId) => {
      let object = null;
      if (_.isObject(objectId)) {
        let model = List.get(objectId._id);
        if (model) {
          // eslint-disable-next-line new-cap
          model = new List.model(app.JsonHelper.deepClone(model.attributes));
          model.set(objectId);
          object = model;
        }
      } else {
        object = List.get(objectId);
      }
      if (object) {
        availableObjects.push(object);
      }
    });

    const canBeMoved =
      options.onMove && (!options.canBeMoved || options.canBeMoved()) && !options.isReadOnly;
    $.each(availableObjects, (i, object) => {
      const canBeRemoved =
        (!options.canBeRemoved || options.canBeRemoved(object)) && !options.isReadOnly;
      let className = '';
      if (options.onClick) {
        className += ' clickable';
      }
      let displayName = '';
      if (options.getDisplayName) {
        displayName = options.getDisplayName(object);
      } else if (object.displayNameHtml) {
        displayName = object.displayNameHtml();
      } else {
        displayName = object.displayName();
      }
      let subtitle = '';
      if (options.getSubtitle) {
        subtitle = options.getSubtitle(object).split('\n').join('<br/>');
      }

      const data = {
        className,
        displayName,
        subtitle,
        canBeRemoved,
        canBeMoved,
      };

      const withTemplate = template($('#sublist-row-template').html());
      $itemListPanel.append(withTemplate(data));
      const itemRow = $itemListPanel.find('.list-group-item').last();
      if (options.onClick) {
        itemRow.click((e) => {
          if (!e.originalEvent.isDropdownEvent) {
            options.onClick(object);
          }
        });
      }
      itemRow.find('button.settings').on('click.bs.dropdown', (e) => {
        // Prevent the click to go through to the cell
        e.originalEvent.isDropdownEvent = true;
      });
      const $collapsedDropdown = itemRow.find('.dropdown-menu.collapsed');
      if (options.onRemove) {
        const $removeButton = itemRow.find('button.remove');
        $removeButton.click(() => {
          options.onRemove(object, $removeButton);
          return false;
        });
        $collapsedDropdown.find('.item-remove').click(() => {
          options.onRemove(object);
          return false;
        });
      }
    });

    if (!canBeMoved) {
      return;
    }

    if (this.sortable) {
      this.sortable.destroy();
    }
    // TODO(stas): touching a single item will cause the whole thing to leak
    this.sortable = Sortable.create($itemListPanel[0], {
      handle: '.handle',
      delay: app.sortableDelay(),
      draggable: '.sortable',
      ghostClass: 'ghost',
      onEnd(e) {
        const oldIndex = e.oldIndex >= 0 ? e.oldIndex : -1;
        const newIndex = e.newIndex >= 0 ? e.newIndex : -1;
        if (options.onMove && oldIndex >= 0 && newIndex >= 0 && oldIndex !== newIndex) {
          options.onMove(oldIndex, newIndex);
        }
      },
    });
  },
});
